import React from 'react';
import { TwoDates } from './twodates';
import { DateTime } from "luxon";


export function Timelinelabel({ block, textalign }) {
  let time = "unknown";
  if(block.frontmatter.exactDate){
    let exact = DateTime.fromISO(block.frontmatter.exactDate);
    if( exact < Date.now() ) {
      time = "past";
    } else {
      time = "future"
    }
  }
  return (
    <div className={`w-full p-4 pb-0 timeline-label ${time}`} id={`block-${block.slug.replace(/.*\//, '')}`}>
      <TwoDates block={block} />
    </div>
  )
}
