import React from 'react';
import { Link } from "gatsby";
import truncatise from 'truncatise';
import { MDXRenderer } from 'gatsby-plugin-mdx';

function excerpt(text){
  let body = text.split('---\n')[2]
  let pieces = body.split('<!-- more -->');
  let excerpt = 'excerpt';
  if(pieces.length > 1){
    excerpt = pieces[0];
  } else {
    excerpt = excerpt = truncatise(body, {
      TruncateBy:     'words',  // Options are 'words', 'characters' or 'paragraphs'
      TruncateLength: 50,    	  // The count to be used with TruncatedBy
      StripHTML:      false,    // Whether or not the truncated text should contain HTML tags
      Strict:         true,     // If set to false the truncated text finish at the end of the word
      Suffix:         '...'     // Text to be appended to the end of the truncated text
    })
  }
  excerpt = excerpt.replace(/\\\*/g, '*');
  return excerpt
}


export function Timelinenode({ block }) {
  return (
    <div className={`w-full p-4 timeline-node branch-${block.frontmatter.branch}`} id={block.slug.replaceAll('/', '-')}>
      <h3>{block.frontmatter.title}</h3>
      {block.allText.length > excerpt(block.rawBody).length ? (
      <p>
        {excerpt(block.rawBody)}  {' '}
        <Link to={`../${block.slug}`}>mehr</Link>
      </p>
      ) : (
        <MDXRenderer>{block.body}</MDXRenderer>
      )}
    </div>
  )
}
