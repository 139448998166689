import React from 'react';
import { useBranchTimeline } from '../hooks/useBranchTimeline';
import { Switch } from './switch';
import { Timelinenode } from './timelinenode';
import { Timelinelabel } from './timelinelabel';


export function Timeline({ data, possibleBranches }) {
  const { branch, toggleBranch, branchTimeline } = useBranchTimeline(data);
  return (
    <section className="w-full">
      <Switch value={branch} toggleSwitch={toggleBranch} possibleBranches={possibleBranches} />
      <h2 id="timeline_heading">Timeline</h2>
      <div className="timeline grid">
        {branchTimeline.map((block, index) =>
            false  ? (
            <React.Fragment key={block.frontmatter.title + block.slug}>
              <Timelinenode block={block} />
              <Timelinelabel block={block} textalign="left" />
            </React.Fragment>
          ) : (
            <React.Fragment key={block.frontmatter.title + block.slug}>
              <Timelinelabel block={block} textalign="right" />
              <Timelinenode block={block}  />
            </React.Fragment>
          )
        )}
      </div>
    </section>
  );
}
