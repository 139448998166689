import React, { useState } from 'react';

export function TabelOfContents({ data }) {
  const [isOpen, toggleOpen] = useState(true);

  const smoothScroll = (e, url) => {
    e.preventDefault();
    document.querySelector(url).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    data && (
      <nav
        className="table-of-contentsabsolute hidden md:block md:inline "
        aria-label="Inhaltsverzeichnis"
      >
        <h4 className="select-none">Inhaltsverzeichnis</h4>
        {isOpen && (
          <ul className="ml-4 mt-2">
            {data.map((heading) => (
              <li
                key={heading.url}
                className="text-sm leading-5 text-gray-600 hover:text-gray-900  dark:text-gray-400 dark:hover:text-gray-50 transition-colors cursor-pointer"
              >
                <a
                  href={heading.url}
                  onClick={(e) => smoothScroll(e, heading.url)}
                  className="no-underline"
                >
                  {heading.title}
                </a>
                {heading.items && (
                  <ul className="ml-4 mt-2" style={{ listStyleType: 'circle' }}>
                    {heading.items.map((item) => (
                      <li key={item.url}>
                        <a
                          href={item.url}
                          onClick={(e) => smoothScroll(e, item.url)}
                          className="no-underline "
                        >
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </nav>
    )
  );
}
