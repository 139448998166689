import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { useSiteMetadata } from "../hooks/useMetadata";
import { TabelOfContents } from "../components/tabelOfContents";
import { Seo } from "../components/seo";
import { Timeline } from "../components/timeline";
import { DateTime } from "luxon";

function dtFromGitDate(string) {
  if (string) {
    return DateTime.fromISO(string.replace(" ", "T").replace(" ", ""));
  } else {
    return -Infinity;
  }
}

/*

          <p className="italic text-slate-500">letzte Änderung {gitLatest.gitLogLatestDateTime.toLocaleString(DateTime.DATETIME_MED)} durch {gitLatest.gitLogLatestAuthorName}</p>


function buildGitMain(parent) {
  let gitMain = parent.fields;
  if(gitmMain === null) return gitMain;
  gitMain.file = parent.internal.description.split('"')[1];
  gitMain.gitLogLatestDateTime = dtFromGitDate(gitMain.gitLogLatestDate);
  return gitMain
}

function buildGitTimeline(nodes) {
  return nodes.map((node) => ({
    file: node.parent.internal.description.split('"')[1],
    gitLogLatestDateTime: node?.parent?.fields?.gitLogLatestDate ? dtFromGitDate(node.parent.fields.gitLogLatestDate) : null,
    ...node.parent.fields,
  }));
}

function getGitLatest(gitMain, gitMany) {
  const gitAll = [gitMain, ...gitMany];

  return gitAll.reduce(function (a, b) {
    return (a.gitLogLatestDateTime > b.gitLogLatestDateTime ) ? a : b;
  });
}

*/

export default function PostPage({ data, location }) {
  const { frontmatter, body, excerpt, slug, tableOfContents, parent } =
    data.content;
  const { title, current, branches } = frontmatter;
  console.log({parent})
  /*
  const gitMain = buildGitMain(parent);
  const gitMany = buildGitTimeline(data.timeline.nodes);
  const gitLatest = getGitLatest(gitMain, gitMany);
  */

  let tableOfTimelineItems = [];
  let { items: tocItems } = tableOfContents;
  const { siteUrl } = useSiteMetadata();

  if (data.tableOfTimeline) {
    data.tableOfTimeline.nodes.forEach((node) => {
      console.log("creating table of content for ", node.slug);
      let d = node.frontmatter.date;
      if (node.frontmatter.exactDate) {
        let ex = DateTime.fromISO(node.frontmatter.exactDate);
        if(ex.hour === 0 && ex.minute === 0) {
          d = ex.toLocaleString(DateTime.DATE);
        } else {
          d = ex.toLocaleString(DateTime.DATE);
        }
      }
      tableOfTimelineItems.push({
        url: "#" + node.slug.replaceAll("/", "-"),
        title: `${node.frontmatter.title} (${d})`
      });
    });
  }
  // console.dir({tableOfTimelineItems, tocItems});
  // let table = [...tableOfTimelineItems, ...tocItems];
  let table = tableOfTimelineItems;
  console.dir(table);
  return (
    <Layout>
      <Seo
        title={title}
        description={excerpt}
        pathname={`${siteUrl}/${slug}`}
        article
      />
      <div className="main-grid">
        <section>
          { false &&
          (<p className="mb-2">
            <a href={`/static/${slug.slice(0, -1)}.pdf`} >Leitfaden als PDF</a>
          </p>)
          }
          <TabelOfContents data={table} />
        </section>
        <section>
          <h1 className="mt-0 mb-0">{title}</h1>
          <p>{frontmatter.when}</p>
          <p className="mb-0">{current}</p>
          <Timeline data={data.timeline.nodes} possibleBranches={branches} />
          <MDXRenderer>{body}</MDXRenderer>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String, $slugTimeline: String) {
    content: mdx(slug: { eq: $slug }) {
      id
      body
      slug
      tableOfContents
      frontmatter {
        current
        title
        branches
        when
      }
      parent {
        ... on File {
          fields {
            gitLogLatestAuthorName
            gitLogLatestAuthorEmail
            gitLogLatestDate
          }
          internal {
            description
          }
        }
      }
    }
    tableOfTimeline: allMdx(
      filter: {
        slug: { regex: $slugTimeline }
        frontmatter: { type: { eq: "timeline" } }
      }
      sort: { fields: [slug], order: ASC }
    ) {
      nodes {
        slug
        frontmatter {
          title
          exactDate
          date
        }
      }
    }
    timeline: allMdx(
      filter: {
        slug: { regex: $slugTimeline }
        frontmatter: { type: { eq: "timeline" } }
      }
      sort: { fields: [slug], order: ASC }
    ) {
      nodes {
        rawBody
        allText: excerpt(pruneLength: 25000)
        body
        slug
        frontmatter {
          title
          date
          exactDate
          branch
        }
        parent {
          ... on File {
            fields {
              gitLogLatestAuthorName
              gitLogLatestAuthorEmail
              gitLogLatestDate
            }
            internal {
              description
            }
          }
        }
      }
    }
  }
`;
