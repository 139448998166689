import React from 'react';

export function Switch({ value, toggleSwitch, possibleBranches }) {
  function handleSwitch(event) {
    toggleSwitch(!value);
    if (!document.getElementById('timeline-switch').checked) {
      event.target.style.setProperty('--bg-btn', 'var(--bg-btn-game)');
      event.target.style.setProperty('--btn-color', 'var(--btn-color-game)');
    } else {
      event.target.style.setProperty('--bg-btn', 'var(--bg-btn-web)');
      event.target.style.setProperty('--btn-color', 'var(--btn-color-web)');
    }
  }

  if(possibleBranches?.length === 2) {

    return (
      <div className="flex justify-center gap-2">
        <p >Web</p>
        <div className="btn-status">
          <input
            type="checkbox"
            name="timeline-switch"
            id="timeline-switch"
            className="hidden"
          />
          <label
            htmlFor="timeline-switch"
            className={`btn-change flex items-center p-1 rounded-xl w-12 h-6 cursor-pointer`}
            onClick={handleSwitch}
          ></label>
        </div>
        <p >Game</p>
      </div>
    );
  }
  else {
    // handle more than two possibleBranches
    return (<div></div>)
  }
}
