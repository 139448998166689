import React from 'react';
import { Link } from 'gatsby';

import { DateTime } from "luxon";

export function TwoDates({block}) {
  let data = block?.frontmatter;
  let date = "";
  if(data?.date){
    date = data?.date;
  }
  let displayExact = null;
  let target = '#';
  if(block?.slug) {
    target =`#block-${block.slug.replace(/.*\//, '')}`
  }
  if (data?.exactDate) {
    let dates = data.exactDate.split(' bis ');
    let display = dates.map( d => {
      let e = DateTime.fromISO(d)
      if(e.hour < 3 && e.minute === 0) {
        return e.toLocaleString(DateTime.DATE_FULL);
      } else {
        return e.toLocaleString(DateTime.DATETIME_MED);
      }
    });
    displayExact = display.join(' bis ');
  }
  return (
    <Link
    to={target}
    className="no-underline"
    >
      {displayExact && (
        <b className='block'>{displayExact}</b>
      )}
      {! displayExact && (
      <i>{date}</i>
      )}
    </Link>
  );
}
