import { useEffect, useState } from 'react';

export function useBranchTimeline(data) {
  const [branch, toggleBranch] = useState(true);

  const filterBranch = (arr, branch = true) =>
    arr.filter(
      (block) =>
        block.frontmatter.branch === (branch ? 'web' : 'game') ||
        block.frontmatter.branch === null
    );

  const [branchTimeline, setBranchTimeline] = useState(filterBranch(data));

  useEffect(() => {
    setBranchTimeline(filterBranch(data, branch));
  }, [data, branch]);

  return {
    branch,
    toggleBranch,
    branchTimeline,
  };
}
